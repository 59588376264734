.auth-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 141px 0;
  gap: 48px;

  .title {
    font-size: $title;
    font-weight: 700;
    text-align: center;
  }
  .img-container {
    width: 225px;
    height: 225px;
  }

  img {
    width: 225px;
    height: 225px;
    object-fit: cover;
    max-height: 225px;
    max-width: 225px;
  }
}

.auth-modal-footer {
  padding-top: 48px;
  padding-bottom: 56px;
  display: flex;
  justify-content: center;

  .auth-modal-button {
    width: 448px;
  }
}

@media (min-width: 391px) and (max-width: 820px) {
  .auth-modal-body {
    padding: 48px 33px 0;
    gap: 32px;

    .title {
      font-size: $body;
    }
  }
  .auth-modal-footer {
    padding-top: 32px;
    padding-bottom: 48px;
    .auth-modal-button {
      max-width: 352px;
    }
  }
}

@media (max-width: 390px) {
  .auth-modal-body {
    padding: 48px 33px 0;
    gap: 32px;

    .title {
      font-size: $body;
    }
    .img-container {
      width: 200px;
      height: 200px;
    }

    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      max-height: 200px;
      max-width: 200px;
    }
  }
  .auth-modal-footer {
    padding-top: 32px;
    padding-bottom: 48px;
    .auth-modal-button {
      max-width: 300px;
      width: 80%;
    }
  }
}
