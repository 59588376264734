.change-password-container {
  padding: 24px 0 0 64px;
  background-color: $background-color;
  border-radius: 24px;
}

.change-password-header {
  display: flex;
  align-items: center;

  span {
    font-size: $title;
    font-weight: 500;
  }
}

.change-password-detail {
  .MuiFormControl-root {
    max-width: 488px;
    width: 100%;
  }
}

.change-pass-backbtn-container {
  display: none;
}

.change-password-form {
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  max-width: 479px;

  .MuiFormControl-root {
    width: 100%;
  }
}

.field-name.MuiFormLabel-root {
  color: black;
  font-size: $subTitle;
  width: 100%;
  margin-bottom: 10px;
}

.change-password-button-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 48px;
}

.change-password-header {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .change-password-header {
    gap: 16px;
  }

  .change-password-button-container {
    margin-top: 32px;
  }

  .change-password-header {
    span {
      font-size: $body;
    }
  }

  .field-name.MuiFormLabel-root {
    font-size: $placeholder;
  }
  .change-password-container {
    padding: 24px;
    background-color: $white-color;
  }

  .change-password-detail {
    margin-top: 5px;
    padding: 0;
  }

  .change-pass-backbtn-container {
    display: flex;
  }
}

@media screen and (max-width: 820px) {
  .change-password-container {
    padding: 0;
    background-color: $white-color;
  }
}
