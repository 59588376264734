.add-sale-form .MuiFormControl-root {
  max-width: 488px;
  width: 100%;
}
.title-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 488px;

  margin: 24px 0;
}
.sale-title {
  font-weight: 500;
  font-size: $body;
}
