.forgot-area-container.MuiFormControl-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 7px;
  cursor: pointer;

  span {
    font-weight: 400;
    font-size: $placeholder;
    color: $placeholder-color;
  }
}

.forgot-area-container.MuiFormControl-root {
  margin-top: 16px;
}

.redirect-container {
  margin-top: 32px;
  text-align: center;
  font-size: $body;
  color: $placeholder-color;

  span {
    color: $primary-color;
    text-decoration: none;
    cursor: pointer;
    padding-left: 5px;
    white-space: nowrap;
  }
  .reset-google-auth {
    color: $dark-grey-color;
  }
}

.input-check .MuiFilledInput-underline:before {
  border-bottom: none;
}
.checkbox-container.MuiFormControlLabel-root {
  color: $primary-color;
}

@media only screen and (max-width: 820px) {
  .login-container {
    flex-direction: column-reverse;
    position: static;
  }

  .header-info-container {
    margin: 5%;
  }
  .login-info-header {
    margin: 5%;
  }

  .auth-company-logo {
    width: 100%;
    padding: 20px;
    background-color: $white-color;
    z-index: 1;

    img {
      top: 20px;
      left: 20px;
      width: 34px;
    }
  }

  .login-title,
  .login-subtitle {
    font-size: $body;
  }

  .redirect-container {
    margin-top: 16px;
    font-size: $placeholder;
  }

  .login-field-name {
    font-size: $placeholder;
    margin-bottom: 5px;
  }
}
