.overview-recipe-container {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  padding: 0;
}
.recipe-detail {
  padding: 24px;
  flex: 3 1;
}
.recipe-cover-photo {
  flex: 1 auto;
  max-width: 300px;
  height: 304px;
  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    border-radius: 0px 8px 8px 0px;
  }
}
.recipe-instructions {
  background: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  font-size: $body;
  font-weight: 400;
  color: $dark-grey-color;
  padding: 12px 14px;
  margin: 8px 0;
  text-align: justify;
  white-space: pre-wrap;
  word-break: break-word;
}
.recipe-photos-container {
  display: flex;
  gap: 16px;
  padding: 10px 0px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.recipe-photo {
  border-radius: 8px;
  height: 108px;
  width: 108px;
  flex-shrink: 0;
  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
}
.product-table-block {
  margin-top: 24px;
  padding-left: 4px;
}
@media (max-width: 820px) {
  .overview-recipe-container {
    flex-direction: column;
    margin: 24px 4px;
  }
  .recipe-cover-photo {
    max-width: 100%;
    height: 168px;
    img {
      object-fit: cover;
      object-position: center;
      border-radius: 0 0 8px 8px;
    }
  }
  .recipe-photos-container {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .recipe-instructions {
    margin-left: 4px;
  }
}

@media print {
  .product-table-block {
    display: none;
  }
  .instruction-photos {
    display: none;
  }
}
