.form-label.MuiFormLabel-root {
  color: black;
  margin-bottom: 10px;
  line-height: 24px;
}

.element-container {
  margin: 10px 0 20px 0;
}

.StripeElement {
  display: block;
  max-width: 500px;
  padding: 12px 14px;
  font-size: $placeholder;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.StripeElement--invalid {
  box-shadow: 0px 0px 4px $error-color;
  -moz-box-shadow: 0px 0px 4px $error-color;
  -webkit-box-shadow: 0px 0px 4px $error-color;
}

input::placeholder {
  color: #aab7c4;
}

.name-input {
  margin-bottom: 20px;
  max-width: 500px;
  width: 100%;
}
