.price-input-cell {
  padding-left: 14px;
}
.price-input-value input {
  padding-left: 0;
}

@media (min-width: 821px) {
  .MuiTableContainer-root.add-table-container {
    border-radius: 8px;
    padding: 0;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
    > .main-table {
      border-spacing: 0;
      margin-top: 0;
      padding: 0;

      th {
        background-color: $light-background-color;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: none;
      }
      td {
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .add-table-container {
    .mobile-table-row > .table-row {
      height: 48px;
    }
  }
}
