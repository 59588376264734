.credit-total-container {
  display: flex;
  justify-content: space-between;
  background-color: $gray91-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 14px;
  width: 556px;
  > span:last-child {
    font-weight: 600;
  }
}
.upload-photo-block {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div:first-child {
    display: flex;
    gap: 20px;
  }
}
.view-photos-container {
  margin-top: 24px;
}
.photo-container {
  @extend .recipe-photo-container;
}
.product-photo {
  @extend .recipe-photo;
}
.credit-note-button-container {
  margin-bottom: 24px;
}

.credit-note-container {
  margin-top: 24px;
}

@media (max-width: 820px) {
  .credit-total-container {
    width: 100%;
    background-color: $white-color;
    border-radius: 4px;
    font-size: $placeholder;
  }
  .upload-photo-block {
    gap: 8px;
    > div:first-child {
      flex-direction: column;
      gap: 2px;
    }
  }
  .shadow-margin {
    margin-left: 4px;
  }
}
