.regular-button {
  height: 40px;
}

.error-message {
  margin-top: 5px;
  font-size: $foot-note;
  color: $error-color;
}
.break-word {
  word-break: break-all;
}

.required-sign {
  margin: -2px 0 0 2px;
  color: $error-color;
}

.select__country-flag {
  margin-right: 10px;
}

.button-container {
  display: flex;
  gap: 24px;
}

.autocomplete {
  .input .MuiFilledInput-root {
    min-height: 40px;
    padding-top: 0;
  }
}

.multiple-autocomplete {
  .input .MuiFilledInput-root {
    height: unset;
    min-height: 40px;
  }
}

.list-icon-button.button.MuiButton-root {
  min-width: 40px;
  height: 40px;
  padding: 0;

  .MuiSvgIcon-root {
    height: 24px;
    fill: $primary-color;
  }
}
.css-ktxc6a {
  max-height: fit-content;
}
.MuiDialogActions-root {
  :first-child {
    color: $dark-grey-color;
  }
}
.MuiPickersToolbar-root {
  span {
    color: black;
  }
}

.view-container {
  padding: 24px 4px;
}

/* filter modal mobile starts here */

.MuiBadge-dot {
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
}

.MuiBadge-overlapCircular {
  width: 18px;
  min-width: 15px;
  height: 18px;
  border-radius: 50%;
  top: 20%;
  right: 20%;
}

.filter-modal {
  width: 536px;
  max-width: 90%;
  max-height: 80%;
  display: flex;
  flex-direction: column;

  .modal-body {
    overflow: scroll;
    > div,
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 2px;
    }
  }
}

.filter-title {
  margin-bottom: 10px;
  line-height: 24px;
}

.filter-field-gap {
  margin-top: 22px;
}

/* filter modal mobile ends here */

.list-container {
  .MuiTablePagination-root {
    margin-top: 20px;
    color: $grey-color;
    .MuiSelect-select.MuiSelect-select {
      width: 46px;
      border-bottom: 1px solid $grey-color;
    }
  }
  .MuiTablePagination-selectRoot {
    margin-left: 16px;
  }
}

.MuiTablePagination-root:last-child {
  color: $grey-color;
}

.mobile-button-container {
  display: none;
}

/* page-content container common classes starts here */

.list-page-container {
  height: 100%;
  padding: 24px;
}

.view-header-back-button-container {
  display: flex;
  align-items: center;

  .status-tag {
    margin-left: 14px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 0 0 64px;
}

.form-title-row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.form-title {
  margin-left: 24px;
  font-size: $title;
  font-weight: 500;
}

.form-container {
  flex: 1;
  padding: 24px;
  background-color: $white-color;
  border-top-left-radius: 24px;
  overflow-y: auto;
}

.form-fields-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 24px;
  width: 100%;

  .input,
  .select {
    width: 100%;
  }
}

.form-field-name {
  display: flex;
  margin-bottom: 10px;
}

.form-full-width-field {
  grid-column: 1 / 3; /* span from grid column line 1 to 3 (i.e., span 2 columns) */
}

.form-button-row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 48px;
}

.action-menu {
  .button.MuiButton-root {
    padding: 0 12px 0 24px;
    .material-icons-round {
      display: none;
    }
    .MuiButton-endIcon {
      margin-right: 0;

      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
}
.Toastify__toast-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    border-radius: 4px;
    width: fit-content;
    text-align: center;
  }
}
.toast-background {
  background-color: $primary-color;
  color: $white-color;
}
.toast-body {
  font-weight: 550;
}
.Toastify__close-button--light {
  color: $white-color;
}

.print-table {
  display: none;
}

.bottom-padding {
  padding-bottom: 24px;
}
.success-outlined-button.button {
  border: 1px solid $completed-green-color;
}
.MuiPickersArrowSwitcher-root {
  button {
    padding: 0;
  }
}

.MuiTimePickerToolbar-ampmSelection {
  .Mui-selected {
    color: $primary-color;
  }
}

@media screen and (max-width: 1279px) {
  .form {
    padding: 12px 0 0 24px;
  }
}

@media (min-width: 1279px) {
  .desktop-overflow-y {
    overflow-y: auto;
  }
}

/* page-content container common classes ends here */

@media screen and (max-width: 1024px) {
  /* page-content container common classes starts here (max-width: 1024px)*/
  .form {
    padding: 24px;
  }

  .form-container {
    margin-right: -24px;
  }

  .button-container {
    gap: 8px;
  }

  .list-icon-button.button.MuiButton-root {
    min-width: 36px;
    height: 36px;
    padding: 0;

    .MuiSvgIcon-root {
      height: 24px;
      fill: $primary-color;
    }
  }

  .action-menu .button.MuiButton-root,
  .desktop-button.button.MuiButton-root {
    height: 36px;
  }

  /* page-content container common classes ends here (max-width: 1024px)*/
}

@media (max-width: 820px) {
  /* filter modal mobile starts here */

  .filter-title {
    margin-bottom: 8px;
    font-size: $placeholder;
  }

  .filter-field-gap {
    margin-top: 16px;
  }

  .mobile-button-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .mobile-button-container .button.MuiButton-root {
    min-width: 36px;
    height: 36px;
    padding: 0;

    .MuiSvgIcon-root {
      height: 20px;
      fill: $primary-color;
    }
  }

  /* filter modal mobile ends here */

  /* page-content container common classes starts here (max-width: 820px)*/
  .form {
    height: unset;
    padding: 0;
  }

  .form-fields-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .form-container {
    margin-right: 0;
    padding: 0;
    overflow-y: unset;
  }

  .form-title-row {
    margin-bottom: 24px;
  }

  .form-title {
    margin-left: 16px;
    font-size: $body;
  }

  .form-button-row {
    margin-top: 32px;

    .button.MuiButton-root {
      height: 36px;
      font-size: $placeholder;
    }
  }

  .form-field-name {
    font-size: $placeholder;
    margin-bottom: 8px;
  }

  /* page-content container common classes ends here (max-width: 820px)*/

  /* list page classes starts here (max-width: 820px) */
  .list-page-container {
    padding: 0;
  }

  .desktop-button.button.MuiButton-root {
    display: none;
  }

  .action-menu {
    .menu-title,
    .MuiButton-endIcon {
      display: none;
    }
    .button.MuiButton-root {
      height: 36px;
      min-width: 36px;
      padding: 0;
      .material-icons-round {
        display: inline-block;
      }
    }
  }

  .view-header-back-button-container {
    .status-tag {
      font-size: $caption;
      margin-left: 8px;
      padding: 4px 14px;
    }
  }
  .Toastify__toast-container {
    top: 1em;
    width: 100%;
    text-align: center;
  }
}

@media print {
  .print-header {
    padding-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    img {
      width: 44px;
      height: 44px;
    }
    span {
      font-size: $x-large;
    }
  }
  .print-body-title {
    font-size: $body;
    font-weight: 500;
  }
  .print-table {
    display: block;
    .print-header-table {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding: 12px 14px;
      > span {
        padding: 0 5px;
      }
    }
    .print-header-charts {
      grid-template-columns: repeat(2, 1fr);
    }
    .print-table-body {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin: 14px 0;
      .print-table-row {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        padding: 12px 14px;
        color: $dark-grey-color;
        font-size: $placeholder;
        > span {
          padding: 0 5px;
        }
        .text-center {
          justify-self: center;
        }
      }
      .print-body-charts {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .center-field {
    text-align: center;
  }
}
