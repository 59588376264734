.import-csv-header {
  padding: 24px;
}

.import-csv-body {
  padding-bottom: 56px;
}

.import-users-modal {
  max-width: 766px;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  .modal-body {
    flex-direction: column;
    gap: 24px;
  }
}
.choose-csv-title {
  font-size: $body;
  font-weight: 500;
  > span {
    margin: 0 4px;
    color: $primary-color;
    cursor: pointer;
  }
}

.browse-file-button-container {
  width: 100%;
  height: 132px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $primary-color;
  border-radius: 4px;
  cursor: pointer;
  &:hover,
  &:hover .button.MuiButton-root {
    box-shadow: 0px 0px 8px rgba(169, 20, 93, 0.5);
    -moz-box-shadow: 0px 0px 8px rgba(169, 20, 93, 0.5);
    -webkit-box-shadow: 0px 0px 8px rgba(169, 20, 93, 0.5);
  }

  &:hover .button.MuiButton-root {
    background-color: $primary-hover-color;
  }
}

.progress-green-tick-mark {
  height: 24px;
}

.uploaded-csv-file-container {
  width: 100%;
}

.uploaded-csv-file-title {
  font-size: $body;
  font-weight: 500;
  line-height: 28px;
}

.progressbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.progress-bar {
  flex: 1;
  margin-right: 20px;
}
.progress-bar-title {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  font-size: $body;
  line-height: 27px;
}
.percentage-count {
  color: $grey-color;
}

.progress-btn.MuiIconButton-root {
  padding: 4px;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f0f0f0;
}

.progress-close-icon.MuiSvgIcon-root {
  color: black;
  font-size: 1rem;
}

.uploaded {
  background: $success-color;
}

.csv-upload-process-success-text {
  font-size: $body;
}

.progress-bar-action {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
  align-items: center;
  div {
    text-align: center;
  }
  button {
    padding: 0 48px !important;
  }
}

.csv-error-list {
  margin-bottom: 0;
  padding-left: 20px;
  font-size: $placeholder;
  color: $error-color;
}

@media (max-width: 820px) {
  .import-users-modal {
    .modal-body {
      gap: 16px;
    }
  }

  .import-csv-body {
    padding-bottom: 48px;
  }

  .choose-csv-title,
  .uploaded-csv-file-title,
  .csv-upload-process-success-text {
    font-size: $subTitle;
    line-height: 24px;
  }
  .progress-bar-title {
    font-size: $placeholder;
    line-height: 21px;
  }

  .browse-file-button.button.MuiButton-root {
    height: 36px;
    font-size: $placeholder;
  }

  .progress-bar.MuiLinearProgress-root {
    height: 4px;
    margin-right: 16px;
  }

  .progress-bar-action {
    gap: 8px;
    margin-top: 24px;
  }

  .progress-green-tick-mark {
    height: 20px;
  }
}
