.category-modal {
  max-width: 645px;
  width: 90%;
  max-height: 80vh;
}

.category-modal-body {
  display: block;
  margin-top: 12px;
}

.category-input-button-container {
  display: flex;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
  .input {
    max-width: 512px;
    flex: 1;
  }
}

.category-chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-height: calc(80vh - 314px);
  margin-top: 16px;
  overflow-y: auto;
}

.category-chip {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px 6px 16px;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);

  pre {
    margin: 0;
    font-family: Poppins;
  }

  svg {
    cursor: pointer;
    &:hover {
      color: $primary-hover-color;
    }
  }
}

@media screen and (max-width: 768px) {
  .category-chip {
    font-size: $placeholder;
  }
  .category-input-button-container {
    gap: 14px;
    .input {
      width: 100%;
      flex: unset;
    }
  }

  .category-chips-container {
    margin-top: 12px;
  }

  .category-chip {
    .MuiSvgIcon-root {
      height: 22px;
    }
  }
}
