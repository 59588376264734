@import 'styles/colorPalette';
@import 'styles/typography';
@import 'styles/common-style';
@import 'components/component-styles';
@import '/src/pages/pages';
@import 'react-image-crop/dist/ReactCrop.css';

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  *::placeholder {
    font-size: $placeholder;
    color: $placeholder-color;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

code {
  font-family: Poppins;
}

.MuiFilledInput-root.MuiFilledInput-underline.Mui-disabled:before {
  border-bottom: none;
}

// /* scrollbar starts here */
* {
  scrollbar-color: $active-color white;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  height: 7px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $active-color;
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-grey-color;
}

// /* scrollbar ends here */

.PrivateSwipeArea-root-1 {
  z-index: -1 !important;
}

@media screen and (max-width: 820px) {
  /* width */
  ::-webkit-scrollbar {
    height: 4px;
    width: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
}
