.chart-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 24px;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}

.supplier-spending-chart-container {
  display: flex;
  flex-direction: row;
  max-height: 200px !important;
  .chart-container {
    width: 58%;
  }
  .chart-legends {
    overflow-y: scroll;
    overflow-x: visible;
    margin: 25px 0 25px 0;

    ul {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      list-style-type: none;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: small;
        color: #666666;
        gap: 10px;
        text-decoration: none;
        div {
          width: 20px;
          height: 10px;
        }
      }
    }
  }
}
.daily-sale-line-chart {
  max-height: none !important;
  height: 250px;
}

.purchase-block-row {
  .category-white-block-row {
    gap: 14px !important;

    > div {
      padding: 16px 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        padding: 0;
      }

      :first-child {
        font-size: $body;
        font-weight: 500;
        color: $dark-grey-color !important;
        line-height: 28px;
      }

      :last-child {
        font-size: $large;
        font-weight: 600;
        color: $primary-color;
        line-height: 1;
      }
    }
  }
}

.purchase-block-row {
  .white-block {
    padding: 24px 0;
    justify-content: center;
    > div {
      padding: 0 24px;
    }
  }
}

.doughnut-graph-list-container {
  display: flex;
  gap: 16px;
  margin: 16px 0;
  max-height: 312px;

  > div {
    flex: 1;
  }
  .daily-sales {
    padding: 16px;
    .page-header {
      margin-bottom: 0;
    }
  }
}

.dashboard-chart-menu.action-menu {
  .button.MuiButton-root {
    padding: 0 12px 0 24px;
    font-size: $body;
  }
  .menu-title,
  .MuiButton-endIcon {
    display: flex;
  }

  .menu-container .MuiMenu-paper .MuiMenu-list li {
    padding: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .purchase-block-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    :last-child {
      grid-column: unset;
    }
    .category-white-block-row > div {
      flex: 1;
      max-width: unset;
    }
  }
  .doughnut-graph-list-container {
    flex-wrap: wrap;
    max-height: unset;
    > div {
      flex: 0 0 100%;
    }
  }

  .chart-button-container {
    gap: 16px;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 820px) {
  .purchase-block-row {
    padding-left: 0;
    > div {
      gap: 4px;
      :first-child {
        line-height: 36px;
      }
      :last-child {
        line-height: 18px;
      }
    }
    .white-block {
      padding: 16px 0;
      min-width: auto;
      > div {
        padding: 0 16px;
      }
    }
    .category-white-block-row {
      gap: 6px !important;
      > div {
        padding: 10px 14px;
        :first-child {
          font-size: $placeholder;
          line-height: 20px;
        }
        :last-child {
          font-size: $subTitle;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .dashboard-chart-menu.action-menu {
    .button.MuiButton-root {
      padding: 0 6px 0 14px;
      font-size: $placeholder;
    }
  }

  .dashboard-container
    .purchase-block-row.dashboard__pending-invoices-sales-info-block-row {
    margin-bottom: 12px;
  }
  .doughnut-graph-list-container {
    margin-top: 12px;
    gap: 12px;
    .dashboard-sub-heading {
      font-size: $placeholder;
    }
    .page-header {
      align-items: center;
    }
  }
  .recent-price-change-container {
    gap: 12px;
  }
  .supplier-spending-chart-container {
    .chart-container {
      width: 54%;
    }
    .chart-legends {
      margin: 25px 10px 25px 10px;
      ul {
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .purchase-block-row {
    grid-template-columns: repeat(1, 1fr);
    .category-white-block-row {
      gap: 12px !important;
    }
  }
  .supplier-spending-chart-container {
    .chart-container {
      width: 54%;
    }
    .chart-legends {
      margin: 25px 0px 25px 5px;
      ul {
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }
  }
}
