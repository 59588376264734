.supplier-details-container {
  > :last-child {
    grid-column: 1/3;
  }
}

.inventory-sypplier-list {
  list-style-type: circle;
  margin: 0;
  padding: 0 0 0 30px;
}

@media (max-width: 820px) {
  .supplier-details-container {
    > :last-child {
      grid-column: 1/2;
    }
  }
}
