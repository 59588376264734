.card-detail-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: $title;
  font-weight: 500;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 30px;
  > div {
    font-weight: 600;
    font-size: $xx-large;
  }
}
.card-screen-container {
  width: 80%;
  margin: auto;
}
.card-detail-row {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  align-items: center;
  padding: 14px 24px;
  background-color: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  font-size: $body;
  font-weight: 500;

  > :first-child {
    display: flex;
    gap: 14px;
    align-items: center;
  }
}

@media (max-width: 820px) {
  .card-detail-header {
    > div {
      font-weight: 500;
      font-size: $body;
    }
  }
  .card-screen-container {
    width: 100%;
    margin: auto;
  }
  .card-detail-row {
    grid-template-columns: 100%;
    margin-left: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    font-size: $subTitle;
    padding: 24px;
  }
}
