.mapper-modal {
  max-height: 80%;
  display: flex;
  flex-direction: column;
}

.mapper-modal-body {
  padding: 5px 24px;
  max-height: 60%;
  overflow: scroll;
}
.accordion-details {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: $main-bg-color;
}
.mapper-error-accordion {
  &.MuiAccordion-root {
    box-shadow: 0px 0px 4px $error-color;
    -moz-box-shadow: 0px 0px 4px $error-color;
    -webkit-box-shadow: 0px 0px 4px $error-color;
  }
}
.mapper-accordion {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
