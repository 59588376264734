$primary-color: #f21d86;
$primary-hover-color: #a9145c;
$active-color: #979797;
$error-color: #dc3545;
$background-color: #f6f6f6;
$light-background-color: #f2f2f2;
$secondary-background-color: #fff5fa;
$white-color: #ffffff;
$placeholder-color: #6d6b6b;
$dark-grey-color: #666666;
$grey-color: #787676;
$gray91-color: #e8e8e8;
$dark-blue-color: #0b4c9f;
$blue-color: #0b4c9f1a;
$completed-green-color: #4cd964;
$completed-light-green-color: #4cd9641a;
$main-bg-color: #f9f9fa;
$success-color: #28a745;
$brown-color: #c97567;
$light-brown-color: #c975671a;
$light-blue-color: #6ecbe8;
$light-pink-color: #fff5fa;
$red-color: #ff6161;
$light-red-color: #ff61611a;
$light-primary-color: #f21d861a;
$disable-pink-color: #ffcde6;
