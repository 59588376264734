.profile-details-container {
  padding: 24px;
}

.text-detail-field-margin-bottom {
  margin-bottom: 8px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-header-title {
  font-size: $title;
  font-weight: 600;
}
.profile-detail-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 24px;
}
.profile-person-detail {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 32px 20px;
  background-color: $white-color;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}
.profile-personal-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 130px;
  max-height: 130px;
  margin-right: 15px;

  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
}

.profile-detail {
  flex: 1;
  height: fit-content;
  background-color: $white-color;
  border-radius: 10px;
  padding: 32px;
  display: grid;
  gap: 15px;
  grid-template-columns: auto auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}

.info-content {
  display: flex;
  flex-direction: column;
}

@media (max-width: 820px) {
  .profile-detail-container {
    flex-direction: column;
    gap: 24px;
  }
  .profile-header-title {
    font-weight: 500;
    font-size: $body;
  }

  .profile-details-container {
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .profile-person-detail {
    flex-wrap: wrap;
  }

  .profile-personal-info {
    flex: 1;
    width: 100%;
    margin-top: 20px;
  }

  .profile-detail {
    grid-template-columns: 1fr;
  }
}
