.plan-container {
  height: calc(100% - 30px);
  overflow: hidden;
  .list-container {
    height: calc(100% - 83px);
  }
  .plan-type-menu {
    margin-top: 20px;
  }
}

.facilities-label-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  span {
    height: 20px;
  }
}

@media (max-width: 820px) {
  .plan-container {
    .list-container {
      margin: 17px -20px 0 4px;
    }
  }
}
