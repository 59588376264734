.upload-button-title {
  font-weight: 500;
  margin-bottom: 12px;
}
.list-row-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.terms-and-condition-form {
  .form-title {
    margin: 0;
  }
}
.upload-list {
  height: 100%;
  overflow-y: scroll;
  padding: 0 4px;
}
