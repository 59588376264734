.form-title-switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-right: 24px;

  .form-title-row {
    margin-bottom: 0;
  }
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-switch-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 15px;
  width: 60px;
  background-color: $white-color;
  border-radius: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}
.toggle-switch {
  position: absolute;
  right: 0;
  top: -7px;
  bottom: 0;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  -webkit-transition: transform 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  border-radius: 50px;
}

.active-switch.toggle-switch {
  background-color: $success-color;
}

.inactive-switch.toggle-switch {
  background-color: $error-color;
  transform: translate3d(-30px, 0, 0);
}

.switch-label {
  //  width: 60px;
  font-size: $placeholder;
}

.active-switch-label {
  color: $success-color;
}

.inactive-switch-label {
  color: $error-color;
}

@media screen and (max-width: 1024px) {
  .form-title-switch-container {
    margin-bottom: 24px;
    padding-right: 0;
  }

  .toggle-switch-container {
    background-color: $background-color;
  }
}
