.panel-layout {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layout-bottom-container {
  display: flex;
  height: calc(100% - 80px);
}

.layout-page-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 280px);
  background-color: $main-bg-color;
  border-top-left-radius: 24px;
  overflow-x: hidden;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.page-title {
  font-size: $title;
  font-weight: 500;
}

@media (max-width: 1279px) {
  .layout-bottom-container {
    min-width: unset;
    width: 100%;
    height: 100%;
  }
  .layout-page-container {
    width: 100%;
    height: calc(100% - 80px);
    background-color: $white-color;
    overflow-y: auto;
  }
}

@media (max-width: 1024px) {
  .layout-page-container {
    height: calc(100% - 74px);
    margin-top: 74px;
  }

  .page-title {
    font-size: $body;
  }
}

@media screen and (max-width: 820px) {
  .panel-layout {
    position: static;
    inset: unset;
    min-height: 100%;
  }

  .layout-page-container {
    padding: 8px 24px 24px 24px;
  }

  .mobile-action-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (max-width: 340px) {
  .page-header {
    flex-wrap: wrap;
  }
}
