.list-row {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  > :first-child {
    color: $dark-grey-color;
  }
  > :last-child {
    display: flex;
    gap: 12px;
    .button.MuiButton-root {
      height: 32px;
      font-weight: 400;
      font-size: $subTitle;
    }
  }
}

.file-list {
  color: $dark-grey-color;
  text-decoration: none;
}

@media (max-width: 820px) {
  .list-row {
    display: none;
  }
  .list-row-button > .button {
    height: 32px;
  }
  .collapse-row {
    padding: 14px;
    gap: 14px;
    display: flex;
    justify-content: flex-end;
  }
}
