.user-dp-upload {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: $placeholder-color;

  .profile-dp-upload {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 60px;

    .profile-close-btn {
      top: 6px;
      background: $primary-color;
      padding: 1px;
      right: 6px;
      z-index: 2;
      border: none;
      border-radius: 10px;
      font-size: $caption;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: absolute;
      &:hover {
        box-shadow: 0px 0px 4px $primary-color;
        -moz-box-shadow: 0px 0px 4px $primary-color;
        -webkit-box-shadow: 0px 0px 4px $primary-color;
      }
    }

    .user-dp {
      cursor: default;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .profile-dp-container {
      position: relative;
    }
  }

  .user-dp-upload {
    background: none;
    border: none;
    color: $placeholder-color;
  }
  .file-upload-container {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .file-upload-container p {
    font-size: $subTitle;
    font-weight: 500;
    color: $active-color;
    word-break: break-word;
    overflow: hidden;
    cursor: pointer;
    margin-left: 18px;
    flex: 1;
  }
}
.input-file-type {
  display: none;
  flex: 1;
}

.close-icon.MuiSvgIcon-root {
  color: $white-color;
  font-size: 1rem;
}

.image-edit-modal {
  max-width: 90vw;
  width: 694px;
  color: #000;

  .modal-body {
    > button {
      text-align: right;
    }
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
    padding: 0 24px;

    .image-container {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      overflow: hidden;

      > * {
        flex: 1 1 50%;
        width: calc(50% - 10px);
        max-width: 300px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      canvas {
        position: relative;

        &::before {
          content: 'Preview';
          position: absolute;
          bottom: -5px;
          right: -5px;
          font-size: $placeholder;
          color: $white-color;
          text-shadow: 0px 0px 4px rgba(255, 255, 255, 1);
        }
      }
    }
  }
}

.button.MuiButton-root.toggle-aspect-button {
  height: 32px;
  font-size: $placeholder;
  font-weight: 400;
}

.raw-image {
  flex: 1 1 50%;
}

@media (max-width: 767px) {
  .user-dp-upload {
    display: block;
  }
  .user-dp-upload .profile-dp-upload {
    margin: 0 0 16px 0;
  }
  .user-dp-upload .file-upload-container p {
    font-size: $placeholder;
    margin: 0 0 0 8px;
  }
}

@media (max-width: 820px) {
  .user-dp-upload .profile-dp-upload {
    margin-right: 16px;
    width: 80px;
    height: 80px;
  }
  .user-dp-upload .profile-dp-upload .user-dp {
    height: 80px;
    width: 80px;
  }
}
