.payment-container {
  .plan-header > div {
    font-weight: 600;
    font-size: 36px;
  }
  .payment-details {
    padding: 32px;
    .payment-plan {
      margin-top: 78px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      padding: 14px 24px;
      .payment-plan-title {
        font-size: 1.5rem;
        font-weight: 500;
      }
      .payment-plan-price {
        font-size: 1.5rem;
        font-weight: 500;
        color: #f21d86;
      }
    }
    .payment-total-container {
      justify-content: center;
      margin-top: 24px;
      .total-container {
        max-width: 556px;
        width: 100%;
        flex: unset;
      }
      .invoice-total-value {
        font-weight: 600;
      }
    }
    .plan-feature {
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 244px;
      margin-top: 24px;
      > div {
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
        border-radius: 8px;
      }
      .payment-features {
        width: 100%;
        padding: 26px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 24px;
        .payment-features-list {
          display: flex;
          align-items: flex-start;
          // margin-bottom: 24px;

          img {
            margin-right: 12px;
            margin-top: 4px;
          }
          .features-list-name {
            font-weight: 400;
            font-size: $body;
          }
        }
      }
      .payment-card {
        // width: 244px;
        padding: 24px;
      }
    }
    .payment-card {
      > div {
        margin-bottom: 24px;
        font-size: $body;
        font-weight: 500;
      }
      div:first-child {
        line-height: 0;
      }
      div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.pay-now-button-container {
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .payment-container .payment-details .plan-feature {
    grid-template-columns: auto;
  }
}

@media (max-width: 820px) {
  .payment-total-container .total-container {
    background-color: #e8e8e8;
    border-radius: 4px;
  }
  .payment-total-container .total-container .sub-total-container {
    background-color: #ffffff;
    border-radius: 5px 5px 8px 8px;
  }
  .payment-container .payment-details .payment-plan {
    margin-top: 35px;
  }
}

@media (max-width: 768px) {
  .payment-container .payment-details {
    padding: 4px;
  }
  .payment-container .payment-details .plan-feature .payment-features {
    grid-template-columns: auto;
  }
  .payment-container .payment-details .payment-plan {
    margin-top: 63px;
  }
}

@media (max-width: 576px) {
  .payment-container .plan-header > div {
    font-size: 18px;
    font-weight: 500;
  }
  .payment-container .payment-details .payment-plan {
    align-items: start;
    flex-direction: column;
    margin-top: 48px;
    padding: 24px;
    border-radius: 16px;
  }
  .payment-container .payment-details .payment-plan .payment-plan-title {
    margin-bottom: 16px;
  }
  .payment-container .payment-details .payment-plan .payment-plan-title,
  .payment-container .payment-details .payment-plan .payment-plan-price {
    font-size: 18px;
  }
  .payment-container .payment-details .plan-feature .payment-features {
    gap: 16px;
    border-radius: 16px;
  }
  .payment-container
    .payment-details
    .plan-feature
    .payment-features
    .payment-features-list
    .features-list-name {
    font-size: 16px;
  }
  .payment-container .payment-details .plan-feature {
    gap: 16px;
  }
  .payment-container .payment-details .payment-total-container,
  .payment-container .payment-details .plan-feature {
    margin-top: 16px;
  }
  .payment-container .payment-details .payment-card > div {
    margin-bottom: 16px;
    font-size: 16px;
  }
}
