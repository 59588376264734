.list-container.draft-order-details-table {
  margin-top: 0;
}

.order-date-time-picker-row {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  > div {
    max-width: 47%;
    width: 512px;
    min-width: 488px;
  }

  .detail-label {
    margin-bottom: 10px;
  }
}

.order-delivery-details {
  margin: 24px 0;

  .main-details-container {
    margin: 0;
  }
}

.draft-order-details-product-list-container {
  margin-bottom: -14px;
}
.draft-note-container {
  margin-bottom: 24px;
}
.creditnote-container {
  margin-left: 4px;
}

@media screen and (max-width: 820px) {
  .order-date-time-picker-row {
    gap: 16px;
    padding-left: 4px;

    > div {
      max-width: 512px;
      width: 100%;
      min-width: unset;
    }
  }

  .order-delivery-details {
    margin: 16px 0;
  }

  .products-table-title {
    margin: 0;
  }
}
