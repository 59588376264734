.subscriber-container {
  height: calc(100% - 25px);
  overflow: hidden;
  .list-container {
    height: calc(100% - 121px);
  }
}

@media (max-width: 820px) {
  .subscriber-container {
    height: calc(100% - 17px);
    .list-container {
      margin: 17px -20px 0 4px;
      height: calc(100% - 113px);
    }
  }
}
