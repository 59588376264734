.sales-list-container {
  padding-top: 24px;
  height: calc(100% - 24px);
  padding-bottom: 24px;
  display: grid;
  grid-template-rows: max-content auto;
  .list-container {
    height: auto;
  }
}

.grid-three-columns {
  @extend .details-grid-three-columns;
}

.sales-white-block-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
    position: relative;

    :nth-child(2) {
      font-size: $xx-large;
      color: $light-blue-color;
      font-weight: 600;
    }
    :last-child {
      font-weight: 500;
      color: $dark-grey-color;
    }
  }
  :last-child {
    :nth-child(2) {
      color: $primary-color;
    }
  }
}

.sales-date-lable {
  position: absolute;
  top: 0;
  right: 14px;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-bottom: 2px solid $primary-color;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
  padding: 2px 14px 0;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.16));
  font-size: $placeholder;
}

@media (max-width: 820px) {
  .grid-three-columns {
    grid-template-columns: repeat(2, 1fr);
  }
  .sales-list-container {
    .list-container {
      .mobile-table {
        overflow: visible;
      }
    }
  }
  .sales-white-block-row {
    > div {
      :nth-child(2) {
        font-size: $title;
      }
      :last-child {
        font-size: $caption;
      }
    }
  }
  .sales-date-lable {
    font-size: $foot-note;
  }
}

@media print {
  .sales-list-container {
    .list-container {
      display: none;
    }
  }
}
