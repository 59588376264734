.white-block-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
    :first-child {
      font-size: $xx-large;
      color: $light-blue-color;
      font-weight: 600;
      line-height: 54px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    :last-child {
      font-weight: 500;
      color: $dark-grey-color;
      line-height: 24px;
    }
  }
  :last-child {
    :first-child {
      color: $primary-color;
    }
  }
}

.grid-five-columns {
  grid-template-columns: repeat(5, 1fr);
}

.recipe-photo-container {
  background-color: $light-pink-color;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 108px;
  height: 108px;
  border-radius: 8px;
  > span {
    margin: 39px;
    color: $primary-color;
    font-size: $x-large;
  }
}
.optionalPhotosContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 10px;
}
.recipe-photo {
  position: relative;
  width: 108px;
  height: 108px;
  border-radius: 8px;
  > img {
    object-fit: cover;
  }
}
.delete-icon.MuiSvgIcon-root {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: $white-color;
  color: $primary-color;
  font-size: $body;
  border-radius: 4px;
}

.white-block {
  min-width: 182px;
  background-color: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 24px;
}

.upload-recipe-photo-block {
  display: grid;
  gap: 10px;
  > :first-child {
    display: flex;
    gap: 18px;
    align-items: center;
    .error {
      color: $error-color;
      font-size: 0.688rem;
    }
  }
}
.recipe-photo-close-button {
  top: 6px;
  background: $primary-color;
  padding: 1px;
  right: 6px;
  z-index: 2;
  border: none;
  border-radius: 10px;
  font-size: $caption;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  &:hover {
    box-shadow: 0px 0px 4px $primary-color;
    -moz-box-shadow: 0px 0px 4px $primary-color;
    -webkit-box-shadow: 0px 0px 4px $primary-color;
  }
}

.instruction-title {
  margin-top: 16px;
  margin-bottom: 10px;
  font-size: $body;
  font-weight: 400;
}

.product-modal {
  width: 100%;
}

.previously-recipe-container {
  margin: 20px 0;
}
.recipe-chip {
  .MuiChip-colorPrimary {
    background-color: $light-pink-color;
    color: $primary-color;
    font-size: $placeholder;
  }
  .MuiChip-deleteIcon {
    color: $primary-color;
    &:hover {
      color: $primary-color;
    }
  }
}

@media (max-width: 820px) {
  .white-block-row {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 4px;
    > div {
      :first-child {
        font-size: $title;
      }
      :last-child {
        font-size: $caption;
      }
    }
    :last-child {
      grid-column: 1/3;
    }
  }

  .grid-five-columns {
    grid-template-columns: repeat(2, 1fr);
  }

  .upload-recipe-photo-block {
    > :first-child {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .instruction-title {
    font-size: $placeholder;
  }
}

@media screen and (max-width: 640px) {
  .white-block {
    padding: 16px;
  }
}
