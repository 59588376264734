.title {
  font-size: $body;
  font-weight: 500;
  margin-bottom: 12px;
}
.button-row {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.signature-container {
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  margin-bottom: 16px;
  > img {
    aspect-ratio: 600/300;
    width: 100%;
    object-fit: contain;
  }
}
.signatureCanvas {
  aspect-ratio: 600/300;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 820px) {
  .title {
    font-size: $subTitle;
  }
  .signature-container {
    width: 100%;
    margin-left: 4px;
    > img {
      aspect-ratio: 600/300;
      width: 100%;
      object-fit: contain;
    }
    .signatureCanvas {
      aspect-ratio: 600/300;
      width: 100%;
      object-fit: contain;
    }
  }
}
