.add-plan-form {
  .MuiFormControlLabel-label {
    color: black;
  }
}
.popular-plan-dropdown {
  margin-bottom: 20px;
}
.add-plan-form {
  .plan-type-menu {
    margin-top: 0;
  }
}
.plan-form-container {
  height: calc(100vh - 144px);
}
@media (max-width: 1279px) {
  .plan-form-container {
    height: 100%;
  }
}
@media screen and (max-width: 820px) {
  .add-plan-form {
    .form-title-switch-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .plan-type-menu {
      margin-top: 28px;
    }
  }
}
