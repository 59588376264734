.auth-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  inset: 0;
  overflow: auto;
  > div {
    width: 50%;
  }
}
.auth-left-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: $white-color;
}

.auth-company-logo {
  position: fixed;
  top: 40px;
  left: 40px;
  line-height: 1;
  img {
    width: 54px;
  }
}

.auth-content-container {
  max-width: 30.5rem;
  width: 100%;
}

.auth-user-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.auth-title {
  width: max-content;
  font-size: $xx-large;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.auth-subtitle {
  margin-bottom: 64px;
  text-align: center;
  font-size: $body;
  color: $grey-color;
}
.auth-form {
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
  }
}

.visibility-icon {
  cursor: pointer;
}

.modal-body-info {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  text-align: center;
  font-size: $title;
  font-weight: 700;
  img {
    width: 135px;
    height: 135px;
  }
}
.ui-state-error {
  color: $error-color;
  font-size: $foot-note;
}

.auth-right-part {
  background-color: $secondary-background-color;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input-field-margin-bottom {
  margin-bottom: 20px;
}

.auth-right-part__title {
  max-width: 835px;
  padding: 0 10%;
  font-size: $x-large;
  text-align: center;
  color: black;
  font-weight: 400;
}

.image-title-container {
  background-color: $secondary-background-color;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.input-field {
  width: 100%;
}

.input-with-error .MuiFormHelperText-contained {
  width: 100%;
  font-size: 0.688rem;
  margin: 0 !important;
  color: $error-color;
  padding-top: 5px;
}

.input-with-error .MuiFormHelperText-root {
  width: 100%;
  font-size: 0.688rem;
  margin: 0 !important;
  color: $error-color;
  padding-top: 5px;
}

.login-img-container {
  background-color: $secondary-background-color;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  .detail-in-login {
    margin: 0 15%;
    font-size: $x-large;
    text-align: center;
    color: black;
    font-weight: 400;
  }
}

.auth-field-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: $body;
  color: #000;
  line-height: 1.5;
}

.forgot-password-text {
  &:hover {
    text-decoration: underline;
  }
}

.back-to-login-text {
  width: 100%;
  margin-top: 16px;
  text-align: right;
  line-height: 1;
  span {
    font-size: $placeholder;
    color: $placeholder-color;
    cursor: pointer;
  }
}

.auth-button.button.MuiButton-root {
  width: 100%;
  height: 48px;
  margin-top: 48px;
}

.code-auth-title {
  width: unset;
}

@media screen and (max-width: 820px) {
  .auth-company-logo {
    top: 0;
    left: 0;
    height: 74px;
    padding: 20px;
    z-index: 1;

    img {
      width: 34px;
    }
  }
}

@media only screen and (max-width: 820px) {
  .auth-container {
    flex-direction: column-reverse;
    position: static;

    > div {
      width: 100%;
    }
  }
  .modal-cancel-overflow {
    flex-direction: column-reverse;
    position: fixed;
    overflow-y: hidden;
    > div {
      width: 100%;
    }
  }
  .modal-body-info {
    gap: 32px;
    font-weight: 500;
  }

  .auth-content-container {
    padding: 0 24px 82px 24px;
  }

  .auth-left-part {
    padding: 0;
  }

  .auth-right-part__title,
  .auth-title {
    font-size: $title;
  }

  .auth-title {
    margin-bottom: 8px;
  }

  .auth-user-info {
    margin-top: 20px;
  }
  .auth-field-name {
    margin-bottom: 8px;
    font-size: $placeholder;
  }

  .auth-subtitle {
    font-size: $placeholder;
    margin-bottom: 16px;
  }

  .auth-right-part {
    margin-top: 74px;
    img {
      height: 220px;
      width: 272px;
    }
  }
  .login-img-container {
    gap: 24px;
    padding: 30px;
    img {
      height: 220px;
    }
    .detail-in-login {
      font-size: $body;
      margin: 0;
    }
  }

  .input-field-margin-bottom {
    margin-bottom: 16px;
  }

  .auth-button.button.MuiButton-root {
    margin-top: 32px;
  }
}

@media screen and (max-width: 640px) {
  .auth-title,
  .auth-right-part__title {
    font-size: $body;
  }
}

.hr {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  width: 100%;

  margin: 40px 0;

  color: #555;
  text-shadow: 0 1px fade(#fff, 40%);

  div {
    font-size: 1.2em;

    &:first-child,
    &:last-child {
      -webkit-flex: 1;
      -moz-flex: 1;
      flex: 1;

      position: relative;

      &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;

        height: 1px;

        background-color: #5f5f5f;

        -webkit-box-shadow: 0 1px 0 fade(#fff, 20%);
        -moz-box-shadow: 0 1px 0 fade(#fff, 20%);
        box-shadow: 0 1px 0 fade(#fff, 20%);
      }
    }

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }
  }
}
