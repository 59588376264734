.add-product-form .MuiFormControl-root {
  max-width: 488px;
  width: 100%;
}
.add-product-label.MuiFormLabel-root {
  color: black;
  margin-bottom: 10px;
  line-height: 24px;
}

@media (max-width: 820px) {
  .add-product-label.MuiFormLabel-root {
    font-size: $placeholder;
  }
}
