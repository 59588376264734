.common-checkbox {
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiFormControlLabel-label {
    margin-left: 10px;
    font-size: $placeholder;
    color: $dark-grey-color;
    line-height: 1.3;
  }
}

.checkbox {
  height: 16px;
  width: 16px;
  border: 1px solid $grey-color;
  border-radius: 4px;
}

.checked-checkbox {
  position: relative;
  height: 16px;
  min-width: 16px;
  border: 1px solid $primary-color;
  border-radius: 5px;
  background-color: $primary-color;
}

.checked-checkbox::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 2px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
