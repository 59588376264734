.list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 56px);
  margin-top: 24px;

  .common-checkbox .MuiFormControlLabel-label {
    margin-left: 0;
  }

  .MuiTableCell-root {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: $subTitle;
    &.table-field-text-center {
      text-align: center;
    }
    &.table-field-text-right {
      text-align: right;
    }
  }

  .row-acitive {
    box-shadow: 0px 0px 4px $success-color;
    -webkit-box-shadow: 0px 0px 4px $success-color;
  }

  th {
    padding-top: 10px;
    padding-bottom: 0;
    font-size: $subTitle;
    white-space: nowrap;
    color: #000;
  }
  th,
  td {
    border: none;
  }
  th {
    padding: 0 14px 0 0;
    font-weight: 500;
    &.MuiTableCell-stickyHeader {
      background-color: $main-bg-color;
    }
  }
  td {
    padding: 4px 14px 4px 0;
    color: $dark-grey-color;
    &:first-child {
      padding-left: 14px;
    }
    &.font-primary {
      color: $primary-color;
    }
  }
  td {
    background-color: $white-color;
  }
  > tbody > tr > td:first-child,
  th:first-child {
    padding-left: 14px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: -4px -4px 4px 4px #ffffff;
  }
  > tbody > tr > td:last-child,
  th:last-child {
    padding-right: 14px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 4px -4px 4px 4px #ffffff;
  }
  .MuiTableCell-paddingCheckbox .MuiFormControlLabel-root {
    margin: 0;
  }

  .table-checkbox {
    position: sticky;
    left: 0;
    padding-right: 0;
  }
}

.main-table {
  height: calc(100% - 165px);
  margin-top: -14px;
  padding: 0 4px;
  border-collapse: separate;
  border-spacing: 0 14px;

  > tbody > .MuiTableRow-root {
    height: 40px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  > tbody > .row-active {
    box-shadow: 0px 0px 0px 2px $completed-green-color;
    -webkit-box-shadow: 0px 0px 0px 2px $completed-green-color;
  }
}

.table-checkbox.desktop-checkbox {
  min-width: 40px;
  z-index: 4;
}

.desktop-cell {
  width: fit-content;
}

th .action-cell {
  width: 100%;
  text-align: end;
  color: #000;
}

.action-cell,
.quantity-cell {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  color: $primary-color;

  svg {
    height: 32px;
    width: 32px;
    padding: 4px;
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 4px $primary-hover-color;
      -moz-box-shadow: 0px 0px 4px $primary-hover-color;
      -webkit-box-shadow: 0px 0px 4px $primary-hover-color;
    }
  }
}

.dropdown-cell {
  display: flex;
  justify-content: center;
  .MuiFormControl-root {
    align-items: center;
  }
}

.mobile-cell.MuiTableCell-root,
.mobile-cell.MuiTableCell-root,
.table-collapse-row.MuiTableRow-root {
  display: none;
}

.mobile-cell .MuiInputBase-root {
  height: 27px;
  margin: 4px;
}

.mobile-cell .MuiInputBase-input {
  text-align: end;
}
.no-record-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 300px;
  padding: 0 20px;
}

/* table data process helper class starts here*/

.priceChangeClass {
  display: flex;
  gap: 8px;
  align-items: center;
  :first-child {
    width: 24px;
  }
}
.upward-arrow {
  color: $completed-green-color;
}
.downward-arrow {
  color: $red-color;
}
.quantity-cell {
  justify-content: center;
  align-items: center;
}
.quantity-cell-font {
  width: 30px !important;
  color: #000;
  font-size: $placeholder;
  text-align: center !important;
}

.status-tag {
  width: fit-content;
  padding: 4px 24px;
  font-size: $placeholder;
  line-height: 20px;
  border-radius: 4px;

  &.draft {
    color: $dark-blue-color;
    background-color: $blue-color;
  }

  &.placed,
  &.pending,
  &.unpaid,
  &.partially-reconciled {
    color: $brown-color;
    background-color: $light-brown-color;
  }

  &.completed,
  &.isActive,
  &.new,
  &.applied,
  &.approved,
  &.paid,
  &.active {
    color: $completed-green-color;
    background-color: $completed-light-green-color;
  }

  &.inactive,
  &.requested {
    color: $error-color;
    background-color: $light-brown-color;
  }

  &.uploaded,
  &.accepted,
  &.received,
  &.rejected,
  &.reconciled,
  &.published {
    color: $primary-color;
    background-color: $light-primary-color;
  }
}
.icon-tag {
  display: flex;
  justify-content: center;
  > span {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    text-align: center;
    &.right {
      color: $completed-green-color;
      background-color: $completed-light-green-color;
    }
    &.wrong {
      color: $red-color;
      background-color: $light-red-color;
      > svg {
        padding: 2px 0 0;
      }
    }
  }
}

.desktop-cell .action-cell {
  padding-left: 4px;
}
.ellipsis-class {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.productNote-container {
  color: $primary-color;
  font-size: $foot-note;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recipe-name-cell {
  display: flex;
  align-items: center;
  gap: 13px;
}
.empty-recipe-icon {
  width: 45px;
}

.foo1-pointer:hover {
  color: #f21d86;
}

/* table data process helper class end here*/

@media (max-width: 1279px) {
  .list-container th.MuiTableCell-stickyHeader {
    background-color: $white-color;
  }
}

@media screen and (max-width: 1024px) {
  .list-container {
    padding: 0;
    .MuiTableCell-root {
      font-size: $placeholder;
    }
  }

  .main-table {
    height: calc(100% - 165px);
    th {
      background-color: $white-color;
    }
    > tbody > tr:not(:nth-child(even)) > td {
      height: 40px;
    }
  }
}
@media (max-width: 820px) {
  .list-container {
    margin-top: 17px;
    margin-right: -24px;
    padding-right: 24px;
  }

  .main-table {
    height: calc(100% - 165px);
    border-spacing: 0 7px;
    th {
      padding-bottom: 7px;
    }
    td,
    th {
      font-size: $placeholder;

      &.mobile-cell:nth-last-child(2) {
        text-align: right;
      }
    }
    > tbody > tr:nth-child(odd) > td {
      background-color: $background-color;
    }
    > tbody > tr:not(:nth-child(even)) td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    > tbody > tr:not(:nth-child(even)) td.mobile-cell:nth-last-child(2) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .collapsed-table tr:last-child {
    td:first-child {
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
  .desktop-cell.MuiTableCell-root {
    display: none;
    padding: 0;
  }
  .mobile-cell.MuiTableCell-root,
  .mobile-cell.MuiTableCell-root {
    display: table-cell;
  }

  .table-collapse-row.MuiTableRow-root {
    display: table-row;
    .MuiCollapse-root {
      margin-top: -14px;
      padding: 7px 0;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    }
    > .MuiTableCell-root {
      padding: 0;
    }
  }
  .collapsed-table {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    tr:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    td {
      padding: 7px 14px;
      &:last-child {
        text-align: right;
      }
    }
    tr:first-child td {
      padding: 14px 14px 7px 14px;
    }
  }

  .icon-tag {
    justify-content: flex-end;
  }

  /* table data process helper class mobile view start here */

  .mobile-cell .priceChangeClass {
    justify-content: flex-end;
  }

  .quantity-cell {
    justify-content: flex-end;
    margin-right: -4px;
    padding-right: 4px;
  }

  .status-tag {
    font-size: $caption;
  }

  .action-cell {
    padding: 2px 2px 2px 0;
  }

  .recipe-name-cell {
    gap: 8px;
  }
  .empty-recipe-icon {
    width: 40px;
  }

  /* table data process helper class mobile view end here */

  .mobile-cell.subscriber-action-cell {
    flex: unset !important;
  }
}
