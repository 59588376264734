.invoices-total-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 14px;

  .total-container {
    flex: 0 0 50%;
    background-color: $gray91-color;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    height: fit-content;

    .sub-total-container {
      padding: 14px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      background-color: $white-color;
      border-radius: 8px;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    > :last-child {
      padding: 14px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.invoice-total-value {
  font-weight: 500;
}

.oreder-number-modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

@media (max-width: 820px) {
  .invoices-total-container {
    margin-left: 4px;
    .total-container {
      flex: 0 0 100%;
      background-color: $white-color;
      border-radius: 4px;
      font-size: $placeholder;

      .sub-total-container {
        background-color: $main-bg-color;
        border-radius: 5px 5px 0px 0px;
      }
    }
  }
}
