.invite-user-header {
  display: flex;
  flex-direction: column;
}
.invite-user-title {
  margin-top: 20px;
  font-size: $title;
  font-weight: 500;
}
.invite-user-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 260px);
}
.invite-user-form .MuiFormControl-root {
  max-width: 488px;
  width: 100%;
}
.user-role-select.MuiFormControl-root {
  width: 100%;
}
.invite-user-button-container {
  flex: 1;
  display: flex;
  gap: 15px;
  align-items: flex-end;
}
.module-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}
.card-radius {
  border-radius: 8px;
}
.module-card {
  background: $main-bg-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  width: 100%;
  height: fit-content;
}
.module {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}
.module,
.submodule {
  .MuiSwitch-colorPrimary {
    color: $white-color;
  }
  .MuiSwitch-colorPrimary.Mui-disabled {
    color: $white-color;
  }

  .MuiSwitch-root {
    height: 16px;
    position: relative;
    width: 35px;
    padding: 0;
    .MuiSwitch-switchBase.Mui-disabled {
      ~ .MuiSwitch-track {
        background-color: $gray91-color;
      }
    }
    .MuiSwitch-switchBase.Mui-checked {
      ~ .MuiSwitch-track {
        background-color: $primary-color;
      }
    }
    .MuiSwitch-switchBase.Mui-checked.Mui-disabled {
      ~ .MuiSwitch-track {
        background-color: #f21d8657;
      }
    }
    .MuiSwitch-switchBase {
      position: absolute;
      top: 0;
      padding: 2px 1px;
    }
    .MuiSwitch-track {
      display: flex;
      background-color: $dark-grey-color;
      opacity: 1;
      width: 30px;
      height: 16px;
    }
    .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
    }
  }
}

.submodule {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 1024px) {
  .module-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 820px) {
  .invite-user-form {
    width: 100%;
  }
  .invite-user-container {
    height: calc(100vh - 240px);
  }
  .module-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 569px) {
  .module-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
