.payment-failed {
  margin-top: 84px;
  text-align: center;
  .payment-failed-image {
    line-height: 0;
  }
  .payment-failed-text {
    font-weight: 400;
    font-size: $body;
    color: $dark-grey-color;
    padding-top: 48px;
  }
}
@media (max-width: 820px) {
  .payment-failed {
    margin-top: 41px;
  }
}
@media (max-width: 768px) {
  .payment-failed {
    margin-top: 69px;
  }
}
@media (max-width: 576px) {
  .payment-failed {
    margin-top: 51px;
  }
  .payment-failed .payment-failed-text {
    padding-top: 32px;
  }
  .payment-failed img {
    width: 100%;
  }
}
