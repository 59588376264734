.header-container {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 80px;
  padding: 0 24px;
}

.header-left-part,
.header-time-container,
.header-right-part {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-left-part {
  gap: 16px;
  color: $dark-grey-color;
}

.header-right-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.header-left-part.desktop-subscribe-part {
  white-space: nowrap;
}

.day-name {
  color: $primary-color;
}

.header-time-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 24px;
  color: $grey-color;
}

.burger-menu {
  display: none;
  height: 23px;
  cursor: pointer;
}

.subscribe-button.MuiButton-contained {
  padding: 0 14px;
  color: $primary-color;
  background-color: $secondary-background-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.notification-bell {
  font-size: $x-large;
  color: $dark-grey-color;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: $background-color;
  }
}

.user-profile-dropdown {
  position: relative;
  width: 238px;
  cursor: pointer;
}

.profile-container {
  display: flex;
  justify-content: space-between;
  height: 44px;
  padding: 7px 8px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);

  .user-details {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 12px;
  }

  .user-dp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    color: $primary-color;
    background-color: $secondary-background-color;
    border-radius: 3px;
    overflow: hidden;
  }
}

.user-name {
  max-width: 172px;
  font-size: $caption;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-role {
  font-size: $foot-note;
  color: $grey-color;
}

.profile-expand-more-arrow {
  font-size: $large;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.user-settings {
  position: absolute;
  top: 48px;
  right: 0;
  width: 238px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  z-index: 9;

  > div {
    position: relative;

    > div {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 28px;
      color: $grey-color;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(33, 33, 33, 0.08);
      }
      span {
        font-size: 22px;
        padding-right: 20px;
        line-height: 1;
      }
      svg {
        width: 16px;
        height: 16px;

        path {
          stroke: $grey-color;
        }
      }
    }
  }
}
.desktop-notification-menu-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-close-icon:hover {
  cursor: pointer;
}

.notification-drawer {
  position: absolute;
  top: 67px;
  right: 268px;
  width: 536px;
  height: 538px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  z-index: 9;
  > div {
    position: relative;
    background-color: $white-color;
    border-radius: 16px;
    margin: 24px;
    .notification-modal-header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 34px;
      > span {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
      }
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        > span {
          color: $primary-color;
          font-size: 16px;
        }
      }
    }
    .notification-messages-wrapper {
      display: flex;
      flex-direction: column;
      gap: 14px;
      overflow-y: scroll;
      padding: 2px 0px;
      height: 418px;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        margin: 0 2px;
        background-color: $white-color;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        > span > svg {
          width: 10.99px;
          height: 10.99px;
        }
      }
    }
  }
}

.clear-notification {
  cursor: pointer;
  padding-left: 10px;
}

.user-settings > div > div:hover {
  color: $primary-color;
  background-color: $secondary-background-color;

  svg path {
    stroke: $primary-color;
  }
}

.profile-drawer-container {
  width: 90vw;
  max-width: 385px;
  padding: 30px 40px 30px 30px;

  .profile-container {
    margin-top: 30px;
  }
}

.close-profile-drawer {
  width: 100%;
  text-align: right;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}
.header-profile-img {
  border-radius: 4px;
  width: 28px;
  height: 28px;
}
.header-visibility {
  visibility: hidden;
}

@media (max-width: 1280px) {
  .header-container {
    align-items: center;
  }

  .burger-menu {
    display: block;
  }

  .header-right-part {
    min-width: 0;
    > * {
      min-width: fit-content;
      // overflow: hidden;
    }

    .profile-container {
      display: none;
    }
  }

  .desktop-profile-menu-dropdown,
  .mobile-subscribe-part,
  .mobile-date-time {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 74px;
    padding: 0 24px;
    background-color: $white-color;
    z-index: 9;
  }

  .header-time-container.desktop-cell-time-container,
  .mobile-subscribe-part {
    display: none;
    font-size: $placeholder;
  }

  .mobile-date-time {
    display: flex;
  }

  .mobile-date-time.header-time-container {
    justify-content: flex-start;
    margin: 34px 0;
    padding: 0;
  }
  .notification-drawer {
    right: 50px;
  }
}

@media (max-width: 820px) {
  .header-container {
    justify-content: space-between;
  }

  .mobile-subscribe-part {
    display: none;
  }

  .header-time-container {
    width: 100%;
  }

  .header-right-part {
    gap: 26px;
  }

  .notification-bell {
    font-size: $large;
  }

  .burger-menu {
    min-width: 23px;
  }
  .notification-drawer {
    right: 30px;
  }
}

@media screen and (max-width: 640px) {
  .header-left-part.desktop-subscribe-part {
    display: none;
    min-width: 0;
    > * {
      display: none;
    }
  }

  .mobile-subscribe-part {
    display: block;
    margin: 20px 0;
    > div {
      margin-bottom: 16px;
    }
  }

  .notification-drawer {
    width: auto;
    height: 498px;
    top: 63px;
    right: 24px;
    left: 24px;

    > div {
      margin: 14px;
      .notification-modal-header {
        margin-bottom: 22px;
        > span {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
        > div {
          gap: 13px;
          > span {
            color: $primary-color;
            font-size: 14px;
          }
        }
      }
      .notification-messages-wrapper {
        height: 416px;
      }
    }
  }
}
