.invoice-title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.invoice-status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.status-title {
  color: $dark-grey-color;
}
