.otp-container {
  display: flex;
  gap: 25px;
  justify-content: space-around;
}

.otp-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: #000;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  font-size: $title;
  &:focus {
    outline: none;
  }
}

@media (min-width: 391px) and (max-width: 820px) {
  .otp-container {
    gap: 16px;
  }
  .otp-box {
    max-width: 39px;
    max-height: 39px;
  }
}

@media (max-width: 390px) {
  .otp-container {
    gap: 10px;
  }
  .otp-box {
    max-width: 34px;
    max-height: 34px;
  }
}
