.plans-container {
  width: 100%;
  padding: 40px;
  height: 100%;
}

.plan-header-container {
  top: 0;
  left: 0;
  display: flex;
  padding: 40px 0 40px 40px;
  right: 0;
  background-color: $white-color;
  z-index: 2;
}

.plan-header {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 4px;
  > div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 572px;
    font-size: $title;
    font-weight: 500;
  }
}
.fixed-plan-type-container {
  top: 92px;
  position: sticky;
  background-color: white;
  z-index: 5;
  padding-bottom: 10px;
}
.plan-type-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: fit-content;
  margin-top: 91px;
  font-weight: 600;
  color: $dark-grey-color;
  background-color: $light-background-color;
  border-radius: 24px;

  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 500ms ease-in-out;

  > span {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    border-radius: 24px;
    z-index: 1;
    cursor: pointer;
  }

  .selected-plan {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 125px;
    height: 100%;
    background-color: $primary-color;
    border-radius: 24px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  > span {
    display: flex;
    justify-content: center;
    width: 125px;
  }
  > span:nth-child(2).selected-plan-type ~ .selected-plan {
    transform: translate3d(125px, 0, 0);
  }
}

.selected-plan-type {
  color: $white-color;
}

.plans-grid-container {
  height: calc(100% - 120px);
  overflow: scroll;
}

.plans-grid {
  display: grid;
  gap: 34px;
  margin: 48px 5px 16px 5px;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
}

.plan {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  min-height: 742px;
  padding: 32px;
  border-radius: 20px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.24);
}
// ribbon-corner label
.ribbon-corner {
  width: 200px;
  padding: 16px;
  position: absolute;
  text-align: center;
  color: #f0f0f0;
  background-color: rgb(242, 29, 134);
  padding-top: 35px;
  font-size: 18px;
  font-weight: 500;
}

.cr-sticky {
  position: absolute;
}

/* Positions */

.cr-top {
  top: -4px;
}
.cr-right {
  right: -70px;
}

/* Rotations */

.cr-top.cr-left,
.cr-bottom.cr-right {
  transform: rotate(-45deg);
}

.cr-top.cr-right,
.cr-bottom.cr-left {
  transform: rotate(45deg);
}
// ribbon-corner label end here

.plan-name {
  display: flex;
  justify-content: space-between;
  font-size: $title;
  font-weight: 500;
  line-height: 1;

  > div:last-child {
    font-weight: bold;
  }
}
.plan-name-break {
  width: 80%;
  word-wrap: break-word;
}

.plan-price {
  margin-top: 2px;
  font-size: $xx-large;
  font-weight: bold;

  span {
    padding-left: 5px;
    font-size: $body;
    font-weight: 600;
    color: $grey-color;
  }
}

.plan-facilities {
  margin-top: 32px;
  font-size: $body;
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    > span {
      font-size: 22px;
      color: $dark-grey-color;
      padding-right: 12px;
      line-height: 1;
    }
  }
}

.plan-terms-condition-checkbox.MuiFormControlLabel-root {
  margin-right: 32px;
}

.choose-plan-button.MuiButton-root {
  align-self: center;
  height: 40px;
  padding: 8px 24px;
  color: $primary-color;
  background-color: $white-color;
  border: 1px solid $primary-color;
  border-radius: 20px;

  &:hover {
    background-color: $secondary-background-color;
  }
}
.button-wrapper {
  display: flex;
  justify-content: space-around;
}

.plan-back-button.back-button.MuiButtonBase-root {
  position: absolute;
  left: 0;
}

.selected-plan {
  color: $white-color;
  background-color: $primary-color;

  span,
  .plan-facilities > div span {
    color: $white-color;
  }
  .MuiButton-label {
    color: $primary-color;
  }
}
.selected-button.MuiButton-root {
  background-color: $disable-pink-color;
}

.plan-policy-row {
  .common-checkbox ~ .MuiFormControlLabel-label {
    line-height: 1;
  }
  > label {
    margin-right: 32px;

    .MuiTypography-root {
      font-size: $placeholder;
    }
  }
}

.plan-confirm-button-container {
  display: flex;
  justify-content: center;
}

.plan-confirm-button {
  max-width: 475px;
  width: 100%;
  margin-top: 42px;
}

.terms-policy-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mobile-plan-confirmation-container {
  display: none;
}

@media screen and (max-width: 820px) {
  .plan-header-container {
    display: block;
    &.auth-company-logo {
      height: unset;
    }
  }

  .plan-header {
    background-color: $white-color;
    > div {
      height: 30px;
    }
  }

  .plans-container {
    padding: 74px 20px 18px 20px;
  }

  .plan-type-menu {
    margin-top: 54px;
    font-size: $placeholder;
  }

  .fixed-plan-type-container {
    top: 82px;
  }

  .plan-terms-condition-checkbox.MuiFormControlLabel-root {
    margin-right: 24px;
  }

  .plan {
    min-height: unset;
    padding: 24px;
  }
  .plan-header-container {
    padding: 20px;
  }
  .plan-header {
    margin-top: 16px;

    > div {
      width: 100%;
      font-size: $body;
    }
  }

  .plans-grid {
    gap: 24px;
  }

  .terms-policy-container {
    justify-content: flex-start;
  }

  .choose-plan-button.MuiButton-root {
    margin-top: 50px;
  }
}
// @media screen and (max-width:960px) {
//   .plan-name {
//     max-width: 80%;
//     word-wrap: normal;
//   }
// }

@media screen and (max-width: 640px) {
  .plans-grid {
    grid-template-columns: 1fr;
  }

  .plan-facilities > div {
    font-size: $subTitle;
    align-items: flex-start;
  }

  .plan-name {
    font-size: $body;
    font-weight: 500;
    max-width: 50vw;
  }

  .plan-price {
    font-size: $x-large;
    width: 50vw;
  }

  .plans-mobile-grid-container {
    height: calc(100% - 200px);
  }

  .fixed-plan-type-container {
    top: 52px;
  }
  .terms-policy-container {
    display: none;
  }
  .plan-confirm-button-container {
    display: none;
  }
  .mobile-plan-confirmation-container {
    display: block;
    position: fixed;
    height: 120px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 24px;
  }
}
