.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(196, 196, 196, 0.31);
  background-blend-mode: screen;
  z-index: 10;
}

.modal-content {
  position: relative;
  background-color: $white-color;
  border-radius: 16px;
  margin: 24px;
}

.close-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px 30px 0 0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal-header {
  display: flex;
  align-items: center;
  padding: 24px 24px 36px;
  font-size: $title;
  font-weight: 600;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 36px;
  }
}
.center-title {
  > div {
    justify-content: center;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.modal-body {
  padding: 0 24px;
  display: flex;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 48px 24px 24px 24px;
  gap: 24px;
}
.success-footer {
  justify-content: center;
}

.modal-button.MuiButton-root {
  height: 40px;
  min-width: unset;
  // max-width: 144px;
}
.big-modal-button {
  height: 48px !important;
  width: auto;
  padding: 0 48px;
}
/* delete modal starts here */

.delete-modal {
  width: 648px;
}
.modal-body.delete-modal-body {
  padding: 0 24px 32px;
}
.delete-modal-content {
  width: 100%;
  line-height: 28px;
  max-width: 100%;
  font-size: $body;
  text-align: center;
  font-weight: 500;
  text-align: left;
}
.modal-footer.delete-modal-footer {
  padding-top: 16px;
  padding-bottom: 24px;
}
/* delete modal ends here*/

/*Confirmation modal starts here*/
.confirmation-modal {
  max-width: 528px;
  width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
}

.modal-body.confirmation-modal-body {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 8px;
  > span {
    font-size: $body;
    font-weight: 500;
  }
  > img {
    width: 60px;
  }
}

/*confirmation modal ends here*/

@media (max-width: 820px) {
  .modal-header {
    padding: 14px 14px 24px;
    font-weight: 500;
    > div {
      font-size: $body;
      line-height: 28px;
    }
  }

  .modal-button.MuiButton-root {
    height: 36px;
    font-size: $placeholder;
  }
  .modal-footer {
    padding: 32px 14px 14px 14px;
    gap: 16px;
    flex-wrap: wrap;
  }
  .modal-body {
    padding: 0 14px;
    font-size: $body;
  }
  .modal-body.delete-modal-body {
    padding: 0 14px 32px;
  }

  .big-modal-button {
    height: 40px !important;
    width: 100%;
    max-width: 144px;
  }
  .close-icon-container {
    padding: 20px 20px 0 0;
  }

  /*delete modal mobile starts here */
  .delete-modal {
    width: 380px;
  }
  .modal-header.delete-modal-header {
    padding: 14px 14px 24px;
  }
  .delete-modal-content {
    font-size: $body;
    line-height: 28px;
    font-weight: 500;
  }

  .delete-modal-content {
    font-size: $placeholder;
    font-weight: 400;
  }

  .modal-footer.delete-modal-footer {
    padding-top: 0;
    padding-bottom: 14px;
  }

  .modal-body.confirmation-modal-body {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-top: 10px;
    > span {
      font-size: $placeholder;
      font-weight: 400;
    }
    > img {
      width: 40px;
    }
  }

  /*delete modal mobile ends here*/
}
