.default-gst-input {
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
}

.add-default-gst-label {
  color: black;
  margin-bottom: 10px;
}
@media (max-width: 820px) {
  .add-default-gst-label {
    font-size: 14px;
  }
}
