.billing-overview-detail-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.account-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    white-space: wrap;
    text-overflow: ellipsis;
  }
}

.payment-row-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-info-row {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  align-items: center;
  padding: 12px 24px;
  background-color: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  font-size: $body;
  font-weight: 500;

  > :first-child {
    display: flex;
    gap: 14px;
    align-items: center;
  }
  > :last-child {
    color: $primary-color;
    text-align: end;
  }
}

.MuiRadio-root {
  padding: 0;
}
.accountInfo-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.subscriptionInfo-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.paymentInfo-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

@media (max-width: 900px) {
  .billing-overview-detail-block {
    flex-direction: column;
    gap: 16px;
    margin-left: 4px;
  }
  .payment-info-row {
    grid-template-columns: 100%;
    margin-left: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    font-size: $subTitle;
    position: relative;
    padding: 24px;
    margin-left: 4px;
    > :last-child {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  .subscriptionInfo-buttons {
    gap: 16px;
  }
  .accountInfo-buttons {
    width: fit-content;
    flex-direction: row;
  }
}
