.subscriber-detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.restro-name {
  color: $primary-color;
  font-size: $body;
  font-weight: 500;
}
.subscriber-email {
  font-size: $placeholder;
  color: $dark-grey-color;
}

.subscriber-view-body {
  display: grid;
  grid-template-rows: 50% 50%;
  height: 95%;
}

@media (max-width: 820px) {
  .restro-name {
    font-size: $placeholder;
  }
  .subscriber-email {
    font-size: $caption;
  }
}
