.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 1.25rem;
  color: $dark-grey-color;
}

.records-per-page-container {
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.select.no-per-page-select {
  width: 60px;
  .MuiFilledInput-input {
    padding: 2.5px 8px;
    font-size: $placeholder;
    color: $dark-grey-color;
  }

  &.MuiFormControl-root {
    margin-left: 12px;
  }

  .MuiSelect-iconFilled {
    right: 7px;
  }
}

.page-handler {
  display: flex;
  gap: 16px;
  height: 2.188rem;
  margin-left: 16px;
}

.first-page,
.last-page,
.prev-page,
.next-page,
.page-number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 24px;
  cursor: pointer;

  :disabled {
    cursor: not-allowed !important;
  }
}

.pagination-button-disabled {
  cursor: not-allowed;
}

.first-page span,
.last-page span {
  font-size: $x-large;
}

.page-number {
  cursor: text;
}

.pagination-button-disabled span {
  opacity: 0.7;
}

.prev-page,
.next-page {
  span {
    font-size: $body;
    font-weight: 700;
  }
}

@media screen and (max-width: 820px) {
  .pagination-container {
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 16px;
    padding: 0;
    font-size: $placeholder;
  }

  .pagination {
    margin-left: 0;
  }

  .first-page span,
  .last-page span {
    font-size: $title;
  }

  .page-handler {
    height: 24px;
  }
}
