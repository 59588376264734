.authentication-code-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reset-auth-screen {
  .auth-subtitle {
    display: none;
  }
}
