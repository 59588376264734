.report-container {
  @extend .dashboard-container;
}

.reports-content-container {
  height: calc(100% - 16px);
  margin: 0 -24px;
  padding: 0 24px 24px 24px;
  overflow-y: auto;

  .MuiTabPanel-root {
    padding: 18px 0;
  }
}

.report-page-header {
  margin-bottom: 24px;
}

.category-filter-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.reports-white-block-row {
  margin: 24px 0 16px 0;
  .category-white-block-row {
    .white-block {
      > div {
        color: $light-blue-color;
      }
      .cost-to-sales {
        color: $completed-green-color;
      }
    }
  }
}

.reports-sub-heading {
  @extend .dashboard-sub-heading;
  margin-bottom: 75px;
}

@media screen and (max-width: 1280px) {
  .reports-content-container {
    overflow: hidden;
  }
}

@media screen and (max-width: 1024px) {
  .reports-content-container {
    .MuiTabPanel-root {
      padding: 10px 0;
    }
  }
  .reports-white-block-row {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 820px) {
  .reports-white-block-row {
    margin: 16px 0 12px;
    padding-left: 0;
    .white-block {
      min-width: auto;
    }
  }
  .reports-sub-heading {
    font-size: $placeholder;
    margin-bottom: $subTitle;
  }
}

@media screen and (max-width: 640px) {
  .reports-white-block-row {
    grid-template-columns: repeat(2, 1fr);
    :last-child {
      grid-column: 1/3;
    }
  }
}

@media screen and (max-width: 520px) {
  .cost-sales-row {
    grid-template-columns: repeat(1, 1fr);
    :last-child {
      grid-column: unset;
    }
  }
}

@media screen and (max-width: 375px) {
  .reports-white-block-row {
    grid-template-columns: repeat(1, 1fr);
    :last-child {
      grid-column: unset;
    }
  }
}
