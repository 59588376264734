.main-details-container {
  width: 100%;
  margin-top: 24px;

  > .view-details-inner-block {
    margin-top: 16px;
  }
}

.view-page-container {
  height: 100%;
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
}

.view-details-white-container {
  margin-left: 4px;
  padding: 24px;
  background-color: $white-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.detail-label {
  font-size: $subTitle;
  color: $dark-grey-color;
}

.detail-value,
.details-block-title {
  font-weight: 500;
  font-size: $body;
  word-break: break-all;
}

.details-block-title,
.bottom-gap {
  margin: 16px 0;
}

.details-grid {
  display: grid;
  gap: 16px;
}
.details-grid-two-columns {
  grid-template-columns: repeat(2, 50%);
}
.details-grid-three-columns {
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 820px) {
  .detail-label {
    font-size: $placeholder;
  }
  .detail-value,
  .details-block-title {
    font-size: $subTitle;
    word-break: break-all;
  }

  .main-details-container {
    margin-top: 16px;
  }

  .details-block-title {
    margin-bottom: 8px;
  }

  .view-page-container {
    margin-right: -24px;
    padding: 0 24px 0 0px;
    overflow-x: hidden;
  }

  .view-detail-last-container {
    margin-left: 4px;
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 640px) {
  .view-details-white-container {
    margin: 0;
  }

  .details-grid-two-columns,
  .details-grid-three-columns {
    grid-template-columns: 1fr;
    margin-left: 4px;
  }
}
