.dashboard-container {
  @extend .list-page-container;

  .page-header {
    align-items: unset;
    margin-bottom: 24px;
  }

  .white-block-row.dashboard__pending-invoices-sales-info-block-row {
    margin-bottom: 16px;
    :last-child {
      :first-child {
        color: $light-blue-color;
      }
    }
  }
}

.welcome-text {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
  font-size: $subTitle;
  font-weight: 500;
  width: 500px;

  .user-business-name {
    font-size: $title;
    color: $primary-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.top-ten-items-ordered-table-container.list-container {
  height: calc(100% - 20px);
  margin-top: 12px;
  > div {
    padding-top: 8px;
  }

  .main-table {
    border-spacing: 0 8px;
  }

  th.MuiTableCell-stickyHeader {
    background-color: $white-color;
  }

  .table-header {
    padding: 8px;
  }

  .mobile-table-row {
    margin-bottom: 8px;
  }
}

.dashboard-content-container {
  height: calc(100% - 16px);
  margin: 0 -24px;
  padding: 0 24px 24px 24px;
  overflow-y: auto;

  // padding is not exact 24px because - upper tab has already 6px bottom gap (6 + 18 = 24px)
  .MuiTabPanel-root {
    padding: 18px 0 0 0;
  }
}

.price-change-block-left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;
  font-weight: 500;

  > div:first-child {
    font-size: $subTitle;
    color: #263238;
    line-height: 24px;
  }

  > div:last-child {
    font-size: $caption;
    color: $dark-grey-color;
  }
}

.price-fluctuation {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: $body;
  font-weight: 500;
}

.up {
  color: #34dbc2;
}
.down {
  color: $red-color;
}

.slide-right-button-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 20px;
  background-color: transparent;
}

.slide-left-button-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 0;
  background-color: transparent;
}

.dashboard-sub-heading {
  font-size: $body;
  font-weight: 600;
}

.recent-price-change-date {
  margin-left: 24px;
  font-size: $subTitle;
  font-weight: 500;
  color: $dark-grey-color;
}

.recent-price-change-container-wrapper {
  position: relative;
}

.recent-price-change-container {
  display: flex;
  gap: 16px;
  margin: 0 4px;
  padding: 12px 65px 12px 4px;
  overflow: hidden;

  > .price-change-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 342px;
    min-width: 342px;
    gap: 20px;
    padding: 24px;

    > div:last-child {
      max-height: 50px;
    }
  }
}

@media (max-width: 1279px) {
  .dashboard-container {
    height: unset;
  }

  .dashboard-content-container {
    overflow-y: hidden;
  }
}

@media screen and (max-width: 1024px) {
  .welcome-text {
    display: block;
    font-size: $placeholder;
    text-align: right;
    .user-business-name {
      font-size: $body;
    }
  }

  .dashboard-content-container {
    overflow-y: hidden;
    // padding is not exact 24px because - upper tab has already 6px bottom gap (6 + 10 = 16px)
    .MuiTabPanel-root {
      padding: 10px 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .slide-right-button-container,
  .slide-left-button-container {
    display: none;
  }
  .recent-price-change-container {
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    > .price-change-block {
      width: 266px;
      min-width: 266px;
      padding: 16px;
    }
  }
  .dashboard-sub-heading {
    font-weight: 500;
  }
  .welcome-text {
    width: 250px;
  }
}
@media screen and (max-width: 340px) {
  .welcome-text {
    width: 150px;
  }
}
