.plan-failed-button-contain {
  display: flex;
  justify-content: center;
}
.plan-payment-failed-text {
  padding-top: 24px !important;
}
.plan-auth-button {
  margin-top: 24px !important;
}
