.menu-container .MuiMenu-paper {
  min-width: 230px;
  margin-top: 4px;
  color: $dark-grey-color;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  .action-item-container {
    display: flex;
    gap: 12px;

    &:hover {
      path {
        fill: $primary-color;
      }
      color: $primary-color;
    }
  }

  .MuiMenu-list {
    padding: 0;
    li {
      padding: 8px 14px;
    }
  }
}

.MuiMenu-list {
  .MuiMenuItem-root {
    min-height: unset;
    font-size: $placeholder;
    font-weight: 500;
  }
}
