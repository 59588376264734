.payment-rocessin-page-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  .processing-message {
    text-align: center;
    font-size: $title;
    font-weight: 500;
  }

  .login-button-container {
    display: flex;
    justify-content: center;
    padding-top: 8px;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 600px;
  gap: 16px;

  img {
    height: 118px;
  }
}

@media (max-width: 820px) {
  .payment-rocessin-page-container {
    .processing-message {
      font-size: $body;
    }
    .login-button-container {
      padding-top: 12px;
    }
  }
  .loader {
    gap: 12px;
    img {
      height: 114px;
    }
  }
}
