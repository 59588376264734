.side-menu {
  width: 280px;
  height: 100%;
  padding-top: 24px;
  overflow-y: auto;

  /* Handle */
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.mobile-side-menu {
  display: none;
  max-width: 350px;
  width: 80vw;
  height: calc(100% - 60px);
  margin-bottom: 60px;
}

.header-logo {
  height: 44px;
  margin-right: 212px;
}

.mobile-logo {
  width: 50px;
  margin: 50px 0 50px 60px;
}

.side-bar-mobile-drawer {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  height: 44px;
  margin: 4px 24px;
  padding: 9px 24px;
  font-size: $placeholder;
  font-weight: 500;
  color: $grey-color;
  cursor: pointer;
  text-decoration: none;
  border-radius: 8px;

  > * {
    line-height: 1;
  }

  &.active {
    background-color: $primary-color;
    color: $white-color;
  }

  &:hover:not(.active) {
    img {
      fill: $primary-color;
    }

    color: $primary-color;
  }
}

.menu-icon {
  margin-right: 10px;
}

.menu:hover .menu-stroke-icon {
  path {
    stroke: $primary-color;
  }
}

.menu:hover .menu-fill-icon {
  path {
    fill: $primary-color;
  }
}

.menu.active {
  pointer-events: none;
  .menu-stroke-icon {
    path {
      stroke: $white-color;
    }
  }

  .menu-fill-icon {
    path {
      fill: $white-color;
    }
  }
}

.sub-menu {
  display: block;
  height: 22px;
  margin: 16px 0 16px 80px;
  font-size: $placeholder;
  font-weight: 500;
  color: $grey-color;
  text-decoration: none;
  white-space: nowrap;
}

.active.sub-menu {
  color: $primary-color;
}

@media (max-width: 1279px) {
  .mobile-side-menu {
    display: block;
  }

  .header-logo {
    margin-right: 24px;
  }

  .side-menu {
    width: 0;
    overflow: hidden;
  }

  .side-bar-mobile-drawer {
    display: block;
  }

  .side-bar-menu-bottom-blank-container {
    height: 50px;
  }

  .header-logo {
    height: 34px;
    width: 35px;
    cursor: pointer;
  }
}

@media screen and (max-width: 820px) {
  .header-right-container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 640px) {
  .header-right-container {
    justify-content: flex-end;
  }
}
