.button {
  height: 40px;

  &.MuiButton-root {
    padding: 0 24px;
    height: 40px;
    font-size: $body;
    text-transform: none;
    white-space: nowrap;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.16);
  }
}

.outlined-primary-button.button {
  border: 1px solid $primary-color;
}
.filter-tooltip-title {
  text-align: center;
}
.filter-lable {
  color: $disable-pink-color;
  font-size: $placeholder;
}
.filter-value {
  font-size: $placeholder;
}
.disable-button {
  pointer-events: none;
}

@media screen and (max-width: 820px) {
  .button.MuiButton-root {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  .outlined-primary-button.button,
  .success-outlined-button.button {
    height: 36px;
    font-size: $placeholder;
  }
}
