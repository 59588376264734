@media print {
  .print-table {
    .statement-header {
      grid-template-columns: repeat(5, 1fr);
    }
    .print-table-body {
      .statememt-row {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
