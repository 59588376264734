.select {
  .MuiSelect-icon {
    color: $primary-color;
  }

  .MuiFilledInput-input {
    padding: 10.5px 14px;

    em {
      font-size: $placeholder;
      color: $placeholder-color;
      font-style: normal;
    }
  }

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:hover:before {
    border-bottom: none;
  }

  .MuiFilledInput-root {
    background-color: $white-color;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  }
}

.select-placeholder {
  font-size: $placeholder;
  color: $placeholder-color;
}

.input-with-error .select .MuiFilledInput-root {
  box-shadow: 0px 0px 4px $error-color;
  -moz-box-shadow: 0px 0px 4px $error-color;
  -webkit-box-shadow: 0px 0px 4px $error-color;
}
