.table-header,
.table-row {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 0 12px 0 14px;
  > .desktop-cell:not(.table-checkbox) {
    flex: 1;
    max-width: 350px;
    min-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > * {
    display: flex;
    align-items: center;
    &.font-primary {
      color: $primary-color;
    }
  }
}

.table-header {
  padding: 14px;
  box-shadow: 0px 0px 4px $white-color;
  -moz-box-shadow: 0px 0px 4px $white-color;
  -webkit-box-shadow: 0px 0px 4px $white-color;
  z-index: 10;
}

.mobile-cell {
  display: none;
  align-items: center;
  flex: 1;
}

.table-row {
  height: 40px;
  color: $dark-grey-color;
  background-color: $white-color;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}

.table-collapse-row,
.mobile-table-header,
.mobile-table,
.mobile-table-row {
  display: none;
}

.first-mobile-table-row {
  margin-top: 2px;
}
.red-border-table-row {
  box-shadow: 0 0 4px #ff6161 !important;
}

@media screen and (max-width: 820px) {
  .MuiTableContainer-root,
  .desktop-table-row.MuiTableRow-root,
  .desktop-header.MuiTableHead-root,
  .list-row {
    display: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .mobile-table {
    display: block;
    margin: -4px -24px -4px -4px;
    padding: 0 24px 4px 8px;
    overflow-y: auto;
  }

  .mobile-table-header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: $white-color;
    font-weight: 500;
    z-index: 1;
  }

  .table-header,
  .table-row {
    font-size: $placeholder;
    .desktop-cell {
      display: none;
    }
  }

  .table-header > div:last-child {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  .table-row {
    font-size: $placeholder;
    background-color: $main-bg-color;
    box-shadow: unset;
    -moz-box-shadow: unset;
    -webkit-box-shadow: unset;

    > div:last-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  div.mobile-cell {
    display: flex;

    &:nth-last-child(2) {
      justify-content: flex-end;
    }
  }

  .mobile-table-row {
    display: block;
    margin-bottom: 14px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  }

  .row-active {
    box-shadow: 0px 0px 0px 2px $completed-green-color;
    -webkit-box-shadow: 0px 0px 0px 2px $completed-green-color;
  }

  .collapsed-row {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mobile-table-row .table-row,
  .collapsed-table .table-row {
    // this code is written for showing ellipsis in mobile view - table row
    > div {
      flex: 1;
      min-width: 0;
      &:last-child {
        > span {
          text-align: end;
        }
      }

      > span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &:last-child {
      .mobile-action-cell {
        flex: none;
      }
    }
  }

  .table-collapse-row {
    display: block;

    .table-row {
      box-shadow: unset;
      -moz-box-shadow: unset;
      -webkit-box-shadow: unset;
      background-color: white;
    }

    .collapsed-table {
      padding-top: 14px;

      .table-row {
        height: unset;
        margin: 0;
        padding: 0 14px 14px;

        > div:first-child {
          color: #000;
          font-weight: 500;
        }
      }
    }

    .mobile-cell {
      margin: 0;
      padding: 0;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
